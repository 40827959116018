import * as React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"
import "../components/layout.css"

import HonestLogo from "../images/honest-guide-logo-link-cubed.jpg"

const Honest = () => (
  <div className="hg_layout">
    <Seo title="honestguide.eu" />
    <div className="hg_cont">
      <div className="hg_logo"> 
        <img src={HonestLogo} alt="honest-guide-logo" width="100%" />
      </div>

      <h1>honestguide.eu</h1>

      <h3>You don't need a super hero you just need an hand</h3>

      <div className="hg_card_cont">
        <div className="hg_card">
          <a href="https://www.honestguide.eu/how-to-devide-servers-in-folders-discord" target="_blank" rel="noopener noreferrer">
            <h3>Last Guide</h3>
          </a>
        </div>

        <div className="hg_card">
          <a href="https://www.honestguide.eu/" target="_blank" rel="noopener noreferrer">
            <h3>Web Site</h3>
          </a>
        </div>

        <div className="hg_card">
          <a href="https://www.youtube.com/channel/UCl43qNHIE8HrhZxA4ZZ6UPg" target="_blank" rel="noopener noreferrer">
            <h3>YouTube Channel</h3>
          </a>
        </div>

        <div className="hg_card">
          <a href="https://www.instagram.com/honestguide.eu/" target="_blank" rel="noopener noreferrer">
            <h3>Instagram</h3>
          </a>
        </div>

        <div className="hg_card">
          <a href="https://twitter.com/Honestguide_eu" target="_blank" rel="noopener noreferrer">
            <h3>Twitter</h3>
          </a>
        </div>

        <div className="hg_card">
          <a href="https://www.facebook.com/honestguide.eu" target="_blank" rel="noopener noreferrer">
            <h3>Facebook</h3>
          </a>
        </div>
      </div>
    </div>

    <footer className="hg_footer">
      <p>powered by</p>
      <Link to="/">LINKCUBED</Link>
    </footer>
  </div>
)

export default Honest